import React, { useEffect, useState } from "react";
import "./app.css";
import { ThemeProvider } from "reactro-ui-lib";
import { Cocktail } from "./components/cocktail";
import styled from "styled-components";

import BlueLagoonBack from "./assets/backs/pyramids.png";
import BoyarskyBack from "./assets/backs/boyarsky.jpg";
import WhiteRussianBack from "./assets/backs/white-russian.jpg";
import BFFBack from "./assets/backs/bff.jpg";
import BeesKneesBack from "./assets/backs/bees-knees.jpg";
import BloodyMaryBack from "./assets/backs/bloody-mary.jpg";
import GimletBack from "./assets/backs/gimlet.jpg";
import GinBuckBack from "./assets/backs/gin-buck.jpg";
import ColoradoBulldogBack from "./assets/backs/colorado-bulldog.jpg";
import IrishCoffeeBack from "./assets/backs/irish-coffee.jpg";
import VirginMaryBack from "./assets/backs/virgin-mary.jpg";
import RussoTuristoBack from "./assets/backs/russo-turisto.jpg";
import SangritaBack from "./assets/backs/sangrita.jpg";
import ShirleyTempleBack from "./assets/backs/shirley-temple.jpg";
import RoyRogersBack from "./assets/backs/roy-rogers.jpg";
import NakedAndFamousBack from "./assets/backs/naked-and-famous.jpg";
import Ward8Back from "./assets/backs/ward-8.jpg";
import MicheladaBack from "./assets/backs/michelada.jpg";
import GinAndSinBack from "./assets/backs/gin-and-sin.jpg";

import BeesKneesPic from "./assets/cocktails/bees-knees.jpg";
import BlueLagoonPic from "./assets/cocktails/blue-lagoon.jpg";
import RussoTuristoPic from "./assets/cocktails/russo-turisto.jpg";
import ShirleyTemplePic from "./assets/cocktails/shirley-temple.jpg";
import IrishCoffeePic from "./assets/cocktails/irish-coffee.jpg";
import BloodyMaryPic from "./assets/cocktails/bloody-mary.jpg";
import VirginMaryPic from "./assets/cocktails/virgin-mary.jpg";
import BoyarskyPic from "./assets/cocktails/boyarsky.jpg";
import SangritaPic from "./assets/cocktails/sangrita.jpg";
import BFFPic from "./assets/cocktails/bff.jpg";
import NakedFamousPic from "./assets/cocktails/naked-and-famous.jpg";
import ColoradoBulldogPic from "./assets/cocktails/colorado-bulldog.jpg";
import GimletPic from "./assets/cocktails/gimlet.jpg";
import Ward8Pic from "./assets/cocktails/ward-8.jpg";
import MicheladaPic from "./assets/cocktails/michelada.jpg";
import GinAndSinPic from "./assets/cocktails/gin-and-sin.jpg";
import GinBuckPic from "./assets/cocktails/gin-buck.jpg";
import WhiteRussianPic from "./assets/cocktails/white-russian.jpg";

interface Ingredient {
  icon?: string;
  name: string;
}

export interface CocktailCfg {
  name: string;
  back?: string;
  pic?: string;
  backYPosition?: number;
  flavour?: string;
  desc?: string;
  ingredients?: Ingredient[];
}

const cocktails: CocktailCfg[] = [
  {
    name: "Bee's Knees",
    back: BeesKneesBack,
    pic: BeesKneesPic,
    flavour: "You have chosen wisely, oh wise Chosen One!",
    desc: "In the bustling market of the trade city, a mysterious vendor offers you a golden elixir, the Bees Knees. Crafted from honey and lemon with a touch of gin, this potion is said to bring a burst of invigorating energy and clarity of mind.",
    ingredients: [
      { name: "Джин" },
      { name: "Лимонный сок" },
      { name: "Апельсиновый сок" },
      { name: "Мед" },
    ],
  },
  {
    name: "Bloody Mary",
    back: BloodyMaryBack,
    pic: BloodyMaryPic,
    desc: "At the heart of the darkened tavern, a barkeep with a sinister grin serves you a Bloody Mary. This spicy concoction, rumored to be made from tomato, vodka, and secret spices, is known to rejuvenate weary adventurers and embolden their spirits.",
    ingredients: [
      { name: "Томатный сок" },
      { name: "Лимонный сок" },
      { name: "Водка" },
      { name: "Соль" },
      { name: "Перец" },
      { name: "Табаско" },
      { name: "Ворчестерширский соус" },
    ],
  },
  {
    name: "Blue Lagoon",
    back: BlueLagoonBack,
    pic: BlueLagoonPic,
    flavour: "Jungle gym workouts: vine-swinging for romance and survival.",
    desc: "In the enchanted grotto, the water nymphs present you with a Blue Lagoon. This mesmerizing mix of blue curaçao, vodka, and lemonade shimmers with a magical glow, promising to refresh and rejuvenate weary travelers with a touch of aquatic grace.",
    ingredients: [
      { name: "Водка" },
      { name: "Blue Curacao" },
      { name: "Лимонад" },
    ],
  },
  {
    name: "Boyarski",
    back: BoyarskyBack,
    pic: BoyarskyPic,
    flavour: "Canaglia! En garde monsieur!",
    desc: "At the royal banquet, you are handed a cocktail by none other than d'Artagnan himself. This fiery blend of vodka, grenadine, and hot pepper is known to ignite courage and passion, perfect for those ready to face any duel or adventure.",
    ingredients: [{ name: "Водка" }, { name: "Гренадин" }, { name: "Табаско" }],
  },
  {
    name: "Gin Buck",
    back: GinBuckBack,
    pic: GinBuckPic,
    backYPosition: 25,
    desc: "In the lively forest clearing, a sprightly faun offers you a Gin Buck. This refreshing mix of gin and ginger ale with a squeeze of lime is known for its crisp, invigorating qualities, perfect for a lighthearted romp through the woods.",
    ingredients: [
      { name: "Джин" },
      { name: "Сок лайма" },
      { name: "Имбирный эль" },
    ],
  },
  {
    name: "Colorado Bulldog",
    back: ColoradoBulldogBack,
    pic: ColoradoBulldogPic,
    desc: "Deep within the dwarven halls, a burly bartender serves you a Colorado Bulldog. This creamy blend of vodka, coffee liqueur, and cola is said to fortify the body and mind, preparing you for the challenges ahead.",
    ingredients: [
      { name: "Водка" },
      { name: "Kahlua" },
      { name: "Сливки" },
      { name: "Coca-Cola" },
    ],
  },
  {
    name: "White Russian",
    pic: WhiteRussianPic,
    back: WhiteRussianBack,
    backYPosition: 0,
    flavour: "Отличный план! Надежный, как швейцарские часы!",
    desc: "In a laid-back tavern with an air of nonchalance, a bartender serves you a mysterious potion. This creamy concoction of vodka, coffee liqueur, and cream is perfect for unwinding and embracing a more relaxed, go-with-the-flow attitude.",
    ingredients: [{ name: "Водка" }, { name: "Kahlua" }, { name: "Сливки" }],
  },
  {
    name: "Gimlet",
    back: GimletBack,
    pic: GimletPic,
    flavour: "England expects that every man will do his duty.",
    desc: "Within the shadowy corners of the Thieves’ Guild, you are handed a Gimlet. A sharp blend of gin and lime, this drink is whispered to sharpen the senses and grant the agility of a cat to those who dare to imbibe.",
    ingredients: [{ name: "Джин" }, { name: "Сок лайма" }],
  },
  {
    name: "Irish Coffee",
    back: IrishCoffeeBack,
    backYPosition: 70,
    pic: IrishCoffeePic,
    desc: "In the cozy corner of a rustic inn, the smell of freshly brewed Irish Coffee wafts through the air. Made with hot coffee, Irish whiskey, and a touch of cream, this beverage is renowned for warming the soul and bolstering courage.",
    ingredients: [{ name: "Кофе" }, { name: "Виски" }, { name: "Сливки" }],
  },
  {
    name: "Roy Rogers",
    back: RoyRogersBack,
    desc: "In the saloon of the frontier town, the barkeep serves you a Roy Rogers. This refreshing mix of cola and grenadine is beloved by young adventurers and those who seek a non-alcoholic delight to lift their spirits.",
    ingredients: [{ name: "Гренадин" }, { name: "Кола" }],
  },
  {
    name: "Sangrita",
    back: SangritaBack,
    pic: SangritaPic,
    flavour: "¡Justicia para todos!",
    desc: "In the heart of the desert oasis, a mysterious shaman shares with you a cup of Sangrita. This vibrant blend of citrus, tomato, and spices is known for its revitalizing effects, perfect for quenching thirst under the scorching sun.",
    ingredients: [
      { name: "Томатный сок" },
      { name: "Сок лайма" },
      { name: "Апельсиновый сок" },
      { name: "Соль" },
      { name: "Перец" },
      { name: "Табаско" },
    ],
  },
  {
    name: "Shirley Temple",
    back: ShirleyTempleBack,
    pic: ShirleyTemplePic,
    desc: "At the faerie queen’s banquet, you are offered a Shirley Temple. This sweet, non-alcoholic concoction of grenadine and ginger ale is a favorite among the fae, bringing joy and lightheartedness to all who drink it.",
    ingredients: [
      { name: "Гренадин" },
      { name: "Лимонад" },
      { name: "Имбирный эль" },
    ],
  },
  {
    name: "Virgin Mary",
    back: VirginMaryBack,
    pic: VirginMaryPic,
    backYPosition: 16,
    flavour:
      "Si Dieu a trouvé des protestants parmi eux, qu'il les distingue lui-même. Nous ne saurions le faire.",
    desc: "At dawn, in the serene chapel garden, the healer offers you a Virgin Mary. This non-alcoholic blend of tomato juice, spices, and a dash of Worcestershire sauce is perfect for revitalizing the spirit and cleansing the palate after a long night.",
    ingredients: [
      { name: "Томатный сок" },
      { name: "Лимонный сок" },
      { name: "Соль" },
      { name: "Перец" },
      { name: "Табаско" },
      { name: "Ворчестерширский соус" },
    ],
  },
  {
    name: "Russo Turisto",
    back: RussoTuristoBack,
    flavour: "Руссо туристо - облико морале!",
    pic: RussoTuristoPic,
    desc: "At a bustling caravanserai, a trader from distant lands offers you a Russo Turisto. This exotic mix of vodka and fruit juices is said to grant a sense of adventure and a burst of vitality to those who partake.",
    ingredients: [
      { name: "Водка" },
      { name: "Гренадин" },
      { name: "Blue Curacao" },
      { name: "Сливки" },
    ],
  },
  {
    name: "🇫🇮 BFF",
    back: BFFBack,
    pic: BFFPic,
    backYPosition: 10,
    flavour: "Beautiful Finland of the Future 😢",
    desc: "In a bustling market square, an optimistic bard presents you with a potion. This local specialty symbolizes hope and freedom, celebrating a future free from oppression and tyranny.",
    ingredients: [
      { name: "Сливки" },
      { name: "Молоко" },
      { name: "Сахар" },
      { name: "Водка" },
      { name: "Blue Curacao" },
    ],
  },
  {
    name: "Naked and Famous",
    back: NakedAndFamousBack,
    pic: NakedFamousPic,
    backYPosition: 56,
    flavour: "The only way to get rid of a temptation is to yield to it.",
    desc: "In the hidden speakeasy, you are presented with the Naked and Famous. This daring blend of mezcal, Aperol, and Chartreuse is said to grant a boldness and a touch of mystique to those who dare to indulge.",
    ingredients: [
      { name: "Chartreuse (yellow)" },
      { name: "Aperol" },
      { name: "Tequila Blanco" },
      { name: "Сок лайма" },
    ],
  },
  {
    name: "Ward 8",
    pic: Ward8Pic,
    back: Ward8Back,
    desc: "At the grand celebration in the city square, a local hero toasts with a Ward 8. This classic mix of rye whiskey, citrus, and grenadine is renowned for its balance and is believed to bring good fortune and success.",
    ingredients: [
      { name: "Виски" },
      { name: "Лимонный сок" },
      { name: "Апельсиновый сок" },
      { name: "Гренадин" },
    ],
  },
  {
    name: "Michelada",
    pic: MicheladaPic,
    back: MicheladaBack,
    desc: "In the coastal town, a weathered sailor shares with you a Michelada. This spicy beer cocktail, infused with lime and assorted spices, is perfect for cooling down after a long day under the sun and adds a bit of zest to your evening.",
    ingredients: [
      { name: "Пиво" },
      { name: "Томатный сок" },
      { name: "Сок лайма" },
      { name: "Ворчестерширский соус" },
      { name: "Табаско" },
      { name: "Соль" },
    ],
  },
  {
    name: "Gin and Sin",
    pic: GinAndSinPic,
    back: GinAndSinBack,
    flavour:
      "History, Stephen said, is a nightmare from which I am trying to awake.",
    desc: "In the rogue’s hideout, a shadowy figure offers you a Gin and Sin. This tantalizing mix of gin, fruit juices, and a touch of syrup is said to bring a sense of daring and adventure, perfect for those who live life on the edge.",
    ingredients: [
      { name: "Джин" },
      { name: "Апельсиновый сок" },
      { name: "Лимонный сок" },
      { name: "Гренадин" },
    ],
  },
  /*{
    name: "Gin Tonic",
    pic: GinTonicPic,
    flavour: "Ye olde basic fuel"
  }*/
];

const getCocktailId = (cocktail: CocktailCfg) => {
  return cocktail.name
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^a-z]/g, "");
};

function App() {
  const [scrollY, setScrollY] = useState(0);
  const [isSplashOverlay, setIsSplashOverlay] = useState(true);

  const handleScroll = () => {
    setScrollY(window.scrollY);
    setIsSplashOverlay(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsSplashOverlay(false);
    }, 1500);
  }, []);

  return (
    <div className="spacebar-app">
      <ThemeProvider theme="pigeon">
        <div className={`header ${!isSplashOverlay ? "header-small" : ""}`}>
          <h1>spacebar</h1>
        </div>

        <Layout>
          {cocktails.map((cocktail) => (
            <Cocktail key={getCocktailId(cocktail)} cocktail={cocktail} />
          ))}
        </Layout>
      </ThemeProvider>
    </div>
  );
}

const Layout = styled("div")`
  margin-top: 120px;
  color: white;

  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-template-columns: repeat(1fr, 1fr);
  gap: 0.4rem;
  align-items: stretch;

  @media only screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (min-width: 930px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default App;
