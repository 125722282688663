import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";

interface GlowingTextProps {
  children: ReactNode;
}

export const GlowingText = ({ children }: GlowingTextProps) => {
  const [randomColor, setRandomColor] = useState(getRandomColor());
  const [isGlowing, setIsGlowing] = useState(false);

  useEffect(() => {
    setInterval(() => {
      const isGlowing = randomlyEnable();
      if (isGlowing) {
        setRandomColor(getRandomColor());
      }
      setIsGlowing(isGlowing);
    }, 3_000);
  }, []);

  return (
    <Name glow={randomColor} isGlowing={isGlowing}>
      {children}
    </Name>
  );
};

const randomlyEnable = () => {
  const diceRoll = Math.floor(Math.random() * 7);
  return diceRoll === 6;
};

const getRandomColor = () => {
  const glowColors = ["purple", "blue", "green", "yellow", "red"];

  return glowColors[Math.floor(Math.random() * glowColors.length)];
};

interface NameProps {
  glow: string;
  isGlowing: boolean;
}
const Name = styled("div").withConfig({
  shouldForwardProp: (prop) => !['glow', 'isGlowing'].includes(prop),
})<NameProps>`
  text-align: left;
  ${(props) =>
    props.isGlowing
      ? `animation: glow-${props.glow} 3s ease-in-out infinite;`
      : ""}
`;
