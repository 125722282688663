import React from "react";
import { Dialog, Button } from "reactro-ui-lib";
import styled from "styled-components";
import { CocktailCfg } from "../app";

interface DetailsProps {
  cocktail: CocktailCfg;
  opened: boolean;
  onClose: (event: any) => void;
}

export const Details = ({ cocktail, opened, onClose }: DetailsProps) => {
  const dialogTitle = `💡 Info`;
  return (
    <>
      {opened && (
        <Backdrop>
          <StyledDialog
            back={cocktail.back}
            dialogContent={
              <p>
                {cocktail.desc}
              </p>
            }
            footer={
              <StyledFooter>
                <StyledButton size="lg" onClick={onClose}>
                  OK
                </StyledButton>
              </StyledFooter>
            }
            onClose={onClose}
            title={dialogTitle}
          />
        </Backdrop>
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  background-color: goldenrod;
  color: white;
}
`;

const StyledFooter = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
`;

interface StyledDialogProps {
  back?: string;
  vertPosition?: string;
}

const StyledDialog = styled(Dialog).withConfig({
  shouldForwardProp: (prop) => !["back", "vertPosition"].includes(prop),
})<StyledDialogProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;

  width: 80vw;
  min-height: 30vh;

  text-align: justify;

  background-image: url(${(props) => props.back ?? ""});
  background-size: cover;
  background-position-x: 50%;
  background-position-y: ${(props) => props.vertPosition ?? "40"}%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
`;

const Backdrop = styled("div")`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
`;
