import React, { useState } from "react";
import { Dialog, Button } from "reactro-ui-lib";
import styled from "styled-components";

import { Details } from "./details";
import { CocktailCfg } from "../app";
import { Ingredients } from "./ingredients";

interface PicViewerProps {
  cocktail: CocktailCfg;
  opened: boolean;
  onClose: (event: any) => void;
}

export const PicViewer = ({ cocktail, opened, onClose }: PicViewerProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const [isDescOpened, setDescIsOpened] = useState(false);

  const [isIngredientsOpened, setIngredientsIsOpened] = useState(false);

  const viewDesc = () => {
    setDescIsOpened(true);
  };

  const closeDesc = () => {
    setDescIsOpened(false);
  };

  const viewIngredients = () => {
    setIngredientsIsOpened(true);
  };

  const closeIngredients = () => {
    setIngredientsIsOpened(false);
  };

  const handleImageLoaded = () => {
    setIsImageLoading(false);
  };

  return (
    <>
      {opened && (
        <Backdrop>
          <StyledDialog
            dialogContent={
              <>
                <Pic>
                  {isImageLoading && <LoadingSpinner>🍸</LoadingSpinner>}
                  <img
                    src={cocktail.pic}
                    alt={cocktail.name}
                    className="large-image"
                    onLoad={handleImageLoaded}
                    style={{ display: isImageLoading ? "none" : "block" }}
                  />
                </Pic>

                <Details
                  cocktail={cocktail}
                  opened={isDescOpened}
                  onClose={closeDesc}
                />

                <Ingredients
                  cocktail={cocktail}
                  opened={isIngredientsOpened}
                  onClose={closeIngredients}
                />
              </>
            }
            footer={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  justifyContent: "end",
                }}
              >
                {cocktail.ingredients?.length && (
                  <Button size="sm" onClick={viewIngredients}>
                    🫙
                  </Button>
                )}
                <Button size="sm" onClick={viewDesc}>
                  📜
                </Button>
                <Button size="lg" onClick={onClose}>
                  OK
                </Button>
              </div>
            }
            onClose={onClose}
            title={cocktail.name}
          />
        </Backdrop>
      )}
    </>
  );
};

const LoadingSpinner = styled("div")`
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #22a6b3;
  border-right-color: #22a6b3;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 1.2s linear infinite;

  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const StyledDialog = styled(Dialog)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;

const Pic = styled("div")`
  img {
    max-height: 70vh;
    max-width: 80vw;
  }
`;

const Backdrop = styled("div")`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 200;
`;
