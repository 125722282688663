import React, { useState } from "react";
import { Box, Button } from "reactro-ui-lib";
import styled from "styled-components";
import { PicViewer } from "./pic-viewer";
import { CocktailCfg } from "../app";
import { GlowingText } from "./glowing-text";

interface CocktailProps {
  cocktail: CocktailCfg;
}

export const Cocktail = ({ cocktail }: CocktailProps) => {
  const [isPicOpened, setPicIsOpened] = useState(false);

  const viewPic = () => {
    setPicIsOpened(true);
  };

  const closePic = () => {
    setPicIsOpened(false);
  };

  return (
    <Container>
      <CocktailTile>
        <TileContent back={cocktail.back} vertPosition={cocktail.backYPosition}>
          <Title>
            <GlowingText>{cocktail.name}</GlowingText>
          </Title>
          <Flavour>{cocktail.flavour}</Flavour>
          {cocktail.pic && (
            <>
              <StyledButton size="sm" onClick={viewPic}>
                🔎
              </StyledButton>
              <PicViewer
                cocktail={cocktail}
                opened={isPicOpened}
                onClose={closePic}
              />
            </>
          )}
        </TileContent>
      </CocktailTile>
    </Container>
  );
};

const Container = styled("div")`
  width: 100%;
  margin-bottom: 0.4rem;
  display: flex;
  justify-content: center;
`;

const CocktailTile = styled(Box)`
  * {
    font-size: 12px;
  }

  > * {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
  }
`;

interface TileContentProps {
  back: string | undefined;
  vertPosition: number | undefined;
}
const TileContent = styled("div").withConfig({
  shouldForwardProp: (prop) => !['back', 'vertPosition'].includes(prop),
})<TileContentProps>`
  width: 94vw;
  text-align: right;
  padding: 0.2rem;

  background-image: url(${(props) => props.back ?? ""});
  background-size: cover;
  background-position-x: 50%;
  background-position-y: ${(props) => props.vertPosition ?? "40"}%;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.8);

  min-height: 5rem;

  @media only screen and (min-width: 600px) {
    width: 47vw;
  }
  @media only screen and (min-width: 930px) {
    width: 30vw;
  }

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
`;

const Title = styled("div")`
  grid-column: 1 / 3;
`;

const Flavour = styled("div")`
  font-size: 0.7rem;
  text-align: left;
  margin-top: 1rem;
  padding-right: 0.2rem;
  color: #cabfca;
`;

const StyledButton = styled(Button)`
  height: 2.4rem;
  align-self: end;
`;